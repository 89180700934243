import {
  Flex,
  useToast,
  useColorModeValue,
  Button,
  Text,
  Box,
  Tooltip,
  TagLabel,
  Tag,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  useDeleteBacklinkReportMutation,
  useFetchBacklinkReportsQuery,
} from '../../store/api/backlinkReportApi';
import { useSelector } from 'react-redux';
import { selectDomain } from '../../store/slices/domain.slice';
import { ViewIcon } from '@chakra-ui/icons';
import {
  ColumnDefinitionType,
  BacklinkReport,
  useDialog,
  ColumnSpecialTypes,
  AppText,
  AppColors,
  CellClickEvent,
  AppConfirm,
  AppDataTable,
  ActionsMenu,
  useDataTable,
  AppPaginator,
  useLoading,
  AppButton,
} from '@backlinkit/shared';

const LinkReportsPage: React.FC = () => {
  const title = 'External Link Reports';
  const { setLoading } = useLoading();
  const domain = useSelector(selectDomain);

  const {
    handleOnPageChange,
    handleSortByColumnClick,
    handleTableRowsSelectedChange,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    selected,
    setPagination,
  } = useDataTable<BacklinkReport>({});

  const { data: backlinkReports, refetch: refetchbacklinkReports } = useFetchBacklinkReportsQuery({
    refetchOnMountOrArgChange: true,
  });

  const [deleteBacklinkReportTrigger] = useDeleteBacklinkReportMutation();

  useEffect(() => {
    if (backlinkReports?.pagination) {
      setPagination(backlinkReports?.pagination);
    }
  }, [backlinkReports]);

  const toast = useToast();
  const dialog = useDialog();

  const getBacklinkUrl = (id: any): string => {
    const backlink = domain?.backlinks?.find((x) => x.id === id);
    return backlink ? backlink.url : '';
  };

  const deleteItem = async (id: string) => {
    setLoading(true);
    await deleteBacklinkReportTrigger(id);
    refetchbacklinkReports();
    setLoading(false);
  };

  const reportColumns: ColumnDefinitionType<BacklinkReport>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'expiryDate',
      header: 'Expiry Date',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'emailAddress',
      header: 'Email Address',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'custom',
      header: 'Tag',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return item.tag ? (
          <Tag borderRadius="full" colorScheme={'gray'}>
            <TagLabel>{item.tag?.name}</TagLabel>
          </Tag>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'custom',
      header: 'Backlinks',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return (
          <Box>
            <Tooltip
              label={
                <Flex flexDirection={'column'}>
                  {item.backlinks?.map((item) => (
                    <AppText p="2" key={item.backlink}>
                      {getBacklinkUrl(item.backlink)}
                    </AppText>
                  ))}
                </Flex>
              }
            >
              <span>
                <Tag borderRadius="full" colorScheme={'gray'}>
                  <TagLabel>{item.backlinks && item.backlinks.length}</TagLabel>
                </Tag>
              </span>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      key: 'otpUsedCount',
      header: 'Otp Used Count',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'custom',
      header: 'View',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return (
          // <Flex dir={'row'} align={'center'}>
          //   <Button
          //     display={'flex'}
          //     alignItems={'center'}
          //     ml={'10px'}
          //     border={`1px solid ${AppColors.appBorder}`}
          //     bg={'white'}
          //     borderRadius={'full'}
          //     _hover={{
          //       transform: 'translateY(-2px)',
          //       boxShadow: 'lg',
          //     }}
          //     onClick={() => {
          //       window.open(`/external-report/${item.id}`, '_blank', 'noopener,noreferrer');
          //     }}
          //     size={'sm'}
          //   >
          //     <ViewIcon />
          //   </Button>
          // </Flex>

          <AppButton onClick={() => deleteItem(item.id)}>DELETE</AppButton>
        );
      },
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<BacklinkReport>[]>(reportColumns);

  const handleTableCellClick = async (clickEvent: CellClickEvent<BacklinkReport>) => {};

  const handleDeleteBacklinkClicked = () => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the ${selected.length} selected External Link reports?`}
            onConfirm={async () => {
              onSubmit();

              await handleDelete();
            }}
            onCancel={() => {
              onCancel();
            }}
          ></AppConfirm>
        );
      },
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const deletePromises = selected.map((report) => {
        if (report && report.id) deleteBacklinkReportTrigger(report.id).unwrap();
      });

      await Promise.all(deletePromises);
    } catch (e) {
      toast({
        title: 'Report Delete error.',
        description:
          "We've run into a problem deleting the selected reports, Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected External Reports deleted.',
      description: "We've deleted the external reports you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    refetchbacklinkReports();
    setLoading(false);
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <BaseLayout>
      <Flex flex={1} direction="column">
        <Flex m={4} justifyContent={'space-between'} minH={'65px'} align={'center'}>
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
            {title}
          </Text>
        </Flex>

        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          justify={'space-evenly'}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={backlinkReports?.data || []}
            noDataMessage={'No external link reports added'}
            columns={tableColumns}
            onTableCellClick={handleTableCellClick}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default LinkReportsPage;
