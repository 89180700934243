import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  useFetchAllOrganizationSourceTypesQuery,
  useCreateOrganizationSourceTypeMutation,
  useDeleteOrganizationSourceTypeMutation,
  useUpdateOrganizationSourceTypeMutation,
} from '../../store/api/organizationSourceTypeApi';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  AppPaginator,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  OrganizationSourceEntityType,
  CreateOrganizationSourceEntityTypeForm,
  EditOrganizationSourceEntityTypeForm,
  useDataTable,
  useLoading,
  usePanel,
  AppLoader,
} from '@backlinkit/shared';
import { OrganizationSourceTypeForm } from '../../components/forms/organization-source-form';
import UrlCell from '../../components/backlinks/components/cells/url-cell';

const OrganizationSourceEntityTypes: React.FC = () => {
  const title = 'Organization Source Entity Types';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const {
    data: organizationSources,
    refetch,
    isLoading,
  } = useFetchAllOrganizationSourceTypesQuery({ refetchOnMountArgChange: true });
  const [createTrigger] = useCreateOrganizationSourceTypeMutation();
  const [editTrigger] = useUpdateOrganizationSourceTypeMutation();
  const [deleteTrigger] = useDeleteOrganizationSourceTypeMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<OrganizationSourceEntityType>({});

  const handleCreate = () => {
    panel({
      title: 'Create Organization Source Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationSourceTypeForm
            onSubmit={async (formData) => {
              await upsertSourceType(formData);
              onSubmit();
            }}
          ></OrganizationSourceTypeForm>
        );
      },
    });
  };

  const handleEdit = (item: OrganizationSourceEntityType) => {
    const editForm: OrganizationSourceEntityType = {
      id: item.id,
      name: item.name,
      description: item.description,
    };
    panel({
      title: 'Edit Organization Source Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationSourceTypeForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertSourceType(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDelete = async (item: OrganizationSourceEntityType) => {
    setLoading(true);
    try {
      const handleDelete = deleteTrigger(item.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'Organization Source Type delete error',
        description:
          "We've run into a problem deleting the selected Organization Source Type's, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: "Selected Organization Source Type's deleted",
      description: "We've deleted the Organization Source Type's you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetch();
    setLoading(false);
  };

  const upsertSourceType = async (formData: any) => {
    setLoading(true);
    let editted = formData.id ? true : false;
    try {
      if (!editted) {
        const createFormData = formData as CreateOrganizationSourceEntityTypeForm;
        await createTrigger({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        const editFormData = formData as EditOrganizationSourceEntityTypeForm;
        await editTrigger({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
      }
      toast({
        title: `Organization Source Type ${!editted ? 'added' : 'updated'}`,
        description: `We've ${
          !editted ? 'added' : 'updated'
        } your Organization Source Type for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetch();
    } catch (err) {
      toast({
        title: `Organization Source Type ${!editted ? 'adding' : 'updating'}. error= ${err}`,
        description: `We've run into a problem ${
          !editted ? 'adding' : 'updating'
        } your Organization Source Type, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetch();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: OrganizationSourceEntityType) => {
    panel({
      title: 'Delete Organization Source Type',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Organization Source Type?"
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationSourceEntityType) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEdit(item)}>
            Edit
          </MenuItem>
          {/* <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem> */}
        </MenuList>
      </Menu>
    );
  };

  const sourceTypeColumns: ColumnDefinitionType<OrganizationSourceEntityType>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'custom',
      header: 'Source URL',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => <UrlCell url={`https://portal.backlinkit.com/registration/${item.id}`} />,
    },
    {
      key: 'name',
      header: 'Name',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'description',
      header: 'Description',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      render: renderDefaultTableActions,
    },
  ];

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Flex flex={1} direction="column">
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreate}
          >
            Add Source Type
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={organizationSources || []}
            noDataMessage="No Source Entity Types yet, start by adding some!"
            columns={sourceTypeColumns}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default OrganizationSourceEntityTypes;
