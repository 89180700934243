import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
  Image,
  FormLabel,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon, ViewIcon } from '@chakra-ui/icons';
import {
  AppColors,
  AppConfirm,
  AppDataTable,
  AppPaginator,
  AppText,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  Bug,
  useDataTable,
  useLoading,
  usePanel,
  BugEditForm,
} from '@backlinkit/shared';
import {
  useDeleteBugMutation,
  useFetchAllBugsQuery,
  useUpdateBugMutation,
} from '../../store/api/bugsApi';
import EditBugForm from '../../components/forms/bug-edit-form';

const Bugs: React.FC = () => {
  const title = 'Bugs';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const { data: bugs, refetch: refetchBugs } = useFetchAllBugsQuery({
    refetchOnMountArgChange: true,
  });
  const [deleteBug] = useDeleteBugMutation();
  const [updateBug] = useUpdateBugMutation();
  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<Bug>({});

  const renderDefaultActions = (item: Bug) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEdit(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<ViewIcon />}
            onClick={() => {
              handleView(item);
            }}
          >
            View
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const bugColumns: ColumnDefinitionType<Bug>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'name',
      header: 'Name:',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'description',
      header: 'Description:',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'screenshot',
      header: 'Screenshot:',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => <Image src={item.screenshot} maxH={'40px'} />,
    },
    {
      key: 'custom',
      header: 'Actions:',
      headerSortable: false,
      columnSearchable: false,
      render: renderDefaultActions,
    },
  ];

  const upsertBug = async (bug: any) => {
    debugger;
    const editData = bug as BugEditForm;
    setLoading(true);
    try {
      await updateBug({
        id: editData.id,
        name: editData.name,
        description: editData.description,
        organizationId: editData.organizationId,
        screenshot: editData.screenshot,
        userId: editData.userId,
        isFixed: editData.isFixed,
      }).unwrap();
      toast({
        title: `Bug Report updated`,
        description: `We've updated the bug report for you, come backe to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Bug Report updating error = ${error}`,
        description: `We've run into a problem updating the bug report you selected. COntact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    refetchBugs();
    setLoading(false);
  };

  const handleEdit = (item: Bug) => {
    const editForm: BugEditForm = {
      id: item.id,
      name: item.name,
      description: item.description,
      organizationId: item.organizationId,
      userId: item.userId,
      isFixed: item.isFixed,
      screenshot: item.screenshot,
    };
    panel({
      title: 'Update Bug',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <EditBugForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertBug(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };
  const handleDelete = async (item: Bug) => {
    setLoading(true);
    try {
      const deletePromise = deleteBug(item.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'Bug report Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected bug report. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Bug report deleted',
      description: "We've deleted the bug report you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchBugs();
    setLoading(false);
  };

  const handleView = (item: Bug) => {
    panel({
      title: 'Bug',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <Flex w={'100%'} flexDir={'column'} gap={'15px'}>
            <FormLabel mb={'-10px'}>Name:</FormLabel>
            <AppText>{item.name}</AppText>
            <FormLabel mb={'-10px'}>Description:</FormLabel>
            <AppText>{item.description}</AppText>
            <FormLabel mb={'-10px'}>Fixed:</FormLabel>
            <AppText>{`${item.isFixed}`}</AppText>
            <Image src={item.screenshot} objectFit={'contain'} objectPosition={'center'} />
          </Flex>
        );
      },
    });
  };
  const handleDeleteConfirm = (item: Bug) => {
    panel({
      title: 'Delete FAQ category',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected FAQ category?"
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  return (
    <BaseLayout>
      <Flex flexDir={'column'} flex={1}>
        <Flex w={'100%'} m={4} justify={'start'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={bugs || []}
            noDataMessage="No Bugs yet!"
            columns={bugColumns}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default Bugs;
