import React from 'react';
import {
  Flex,
  Button,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useLoading,
  usePanel,
  useDataTable,
  BacklinkBatch,
  AppPaginator,
  AppText,
  PageChangeEvent,
  BacklinkBatchEditForm,
} from '@backlinkit/shared';
import {
  useDeleteBatchMutation,
  useFetchBatchesByOptionsQuery,
  useUpdateBatchMutation,
} from '../../store/api/backlinkBatchApi';
import { useSelector } from 'react-redux';
import { selectDomain } from '../../store/slices/domain.slice';
import BacklinkBatchForm from '../../components/forms/batch-form';

const Batches: React.FC = () => {
  const title = 'Batches';
  const { setLoading } = useLoading();
  const [updateBatchTrigger] = useUpdateBatchMutation();
  const [deleteBatchTrigger] = useDeleteBatchMutation();
  const panel = usePanel();
  const toast = useToast();
  const {
    handleOnPageChange,
    handleSortByColumnClick,
    handleTableRowsSelectedChange,
    handleSearchChange,
    nextPaginationQueryParams,
    pagination,
    selected,
    setPagination,
  } = useDataTable<BacklinkBatch>({});
  const domain = useSelector(selectDomain);

  const { data: batches, refetch: refetchBatches } = useFetchBatchesByOptionsQuery({
    domainId: undefined ?? domain?.id.toString(),
    pageNumber: nextPaginationQueryParams.page,
    pageSize: nextPaginationQueryParams.pageSize,
    searchParam: nextPaginationQueryParams.searchList,
    sortParams: nextPaginationQueryParams.sortList,
  });

  const upsertBatch = async (formData: BacklinkBatchEditForm) => {
    setLoading(true);
    try {
      await updateBatchTrigger({
        id: formData.id ?? '',
        domainId: domain?.id ?? '',
        name: formData.name,
        price: formData.price,
        notes: formData.notes,
        backlinkIds: formData.backlinkIds,
        batchTypeId: formData.batchTypeId,
        nicheTypeId: formData.nicheTypeId,
        tagIds: formData.tagIds,
      });
    } catch (e) {
      toast({
        title: `Batch updating error = ${e}`,
        description:
          "We've run into an issue updating the batch you selected. Contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Backlink Batch updated',
      description:
        "We've updated the backlink batch you selected. Come back soon to check the status",
      isClosable: true,
      status: 'success',
      duration: 9000,
    });
  };

  const handleBatchEdit = (item: BacklinkBatch) => {
    const formValues: BacklinkBatchEditForm = {
      id: item.id ?? '',
      name: item.name,
      domainId: item.domainId,
      price: item.price,
      notes: item.notes,
      batchTypeId: item.batchTypeId,
      nicheTypeId: item.nicheTypeId,
    };
    panel({
      title: item.name,
      size: 'lg',
      render: (onSubmit) => {
        return (
          <BacklinkBatchForm
            backlinks={item.backlinks}
            form={formValues}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertBatch(formData);
            }}
          />
        );
      },
    });
  };
  const handleDelete = async (item: BacklinkBatch) => {
    try {
      const deletePromise = deleteBatchTrigger(item.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: 'Batch delete error',
        status: 'error',
        description:
          "We've run into an error deleting the batch you selected, Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Selected Batch deleted',
      description: "We've deleted the batch you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  };

  const renderDefaultTableActions = (item: BacklinkBatch) => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          bgColor={'white'}
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleBatchEdit(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDelete(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const batchColumns: ColumnDefinitionType<BacklinkBatch>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date',
    },
    {
      key: 'name',
      header: 'Name',
    },
    {
      key: 'backlinks',
      header: 'Backlinks',
      render: (item) => <AppText size={'xs'}>{item.backlinks?.length}</AppText>,
    },
    {
      key: 'backlinkBatchTags',
      header: 'Tags',
      render: (item) => <AppText size={'xs'}>{item.backlinkBatchTags?.length || 0}</AppText>,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];
  return (
    <BaseLayout>
      <Flex px={6} py={2} flex={1} direction="column">
        <Flex w={'full'} justify={'space-between'} align={'center'}>
          <AppText fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
            Batches
          </AppText>
        </Flex>

        <Flex py={6} flex={1}>
          <Flex
            w={'full'}
            bg={useColorModeValue('white', 'gray.800')}
            rounded={'2xl'}
            overflow={'hidden'}
            padding={'4'}
            flexDir={'column'}
            flex={1}
          >
            <AppDataTable
              columns={batchColumns}
              data={batches?.data || []}
              selectableRows={false}
              noDataMessage="No Batches added yet, start by adding some!"
            />
            <AppPaginator
              pagination={pagination}
              onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
            ></AppPaginator>
          </Flex>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default Batches;
