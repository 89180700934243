import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  AppColors,
  AppConfirm,
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  FAQ,
  OrganizationNewFeatureTypeDto,
  OrganizationNewFeatureTypeEditFormData,
  OrganizationNewFeatureTypeFormData,
  useLoading,
  usePanel,
  AppButton,
  useDataTable,
  AppPaginator,
} from '@backlinkit/shared';

import OrganizationNewFeatureTypesForm from '../../components/forms/orgnization-type-form';
import { ChevronDownIcon, SettingsIcon, DeleteIcon } from '@chakra-ui/icons';
import { Text, useColorModeValue } from '@chakra-ui/react';
import {
  useCreateOrganizationNewFeatureTypeMutation,
  useDeleteOrganizationNewFeatureTypeMutation,
  useFetchAllOrganizationNewFeatureTypesQuery,
  useUpdateOrganizationNewFeatureTypeMutation,
} from '../../store/api/organizationNewFeatureApi';

const OrganizationNewFeatureTypes: React.FC = () => {
  const title = 'Organization New Feature Types';
  const { setLoading } = useLoading();
  const toast = useToast();
  const panel = usePanel();
  const { data: OrganizationNewFeatureTypes, refetch: refetchOrganizationNewFeatureTypes } =
    useFetchAllOrganizationNewFeatureTypesQuery({
      refetchOnMountArgChange: true,
    });
  const [createOrganizationNewFeatureTypes] = useCreateOrganizationNewFeatureTypeMutation();
  const [updateOrganizationNewFeatureTypes] = useUpdateOrganizationNewFeatureTypeMutation();
  const [deleteOrganizationNewFeatureTypes] = useDeleteOrganizationNewFeatureTypeMutation();

  const {
    handleSearchChange,
    handleSortByColumnClick,
    handleOnPageChange,
    pagination,
    setPagination,
    nextPaginationQueryParams,
  } = useDataTable<OrganizationNewFeatureTypeDto>({});

  const upsertOrganizationNewFeatureTypes = async (formData: any) => {
    setLoading(true);
    let eddittedOrganizationNewFeatureTypes = formData.id ? true : false;
    try {
      if (!eddittedOrganizationNewFeatureTypes) {
        const createFormData = formData as OrganizationNewFeatureTypeFormData;
        await createOrganizationNewFeatureTypes({
          name: createFormData.name,
          description: createFormData.description,
        }).unwrap();
      } else {
        const editFormData = formData as OrganizationNewFeatureTypeEditFormData;
        await updateOrganizationNewFeatureTypes({
          id: editFormData.id ?? '',
          name: editFormData.name,
          description: editFormData.description,
        }).unwrap();
      }
      toast({
        title: `Organization New Feature Type ${
          !eddittedOrganizationNewFeatureTypes ? 'added' : 'updated'
        }`,
        description: `We've ${
          !eddittedOrganizationNewFeatureTypes ? 'added' : 'updated'
        } your Organization New Feature Type for you, come back soon to monitor the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchOrganizationNewFeatureTypes();
    } catch (err) {
      toast({
        title: `Organization New Feature Type ${
          !eddittedOrganizationNewFeatureTypes ? 'adding' : 'updating'
        }. error= ${err}`,
        description: `We've run into a problem ${
          !eddittedOrganizationNewFeatureTypes ? 'adding' : 'updating'
        } your Organization New Feature Type, Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    refetchOrganizationNewFeatureTypes();
    setLoading(false);
  };

  const handleCreateOrganizationNewFeatureTypes = () => {
    panel({
      title: 'Create Organization New Feature Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationNewFeatureTypesForm
            onSubmit={async (formData) => {
              await upsertOrganizationNewFeatureTypes(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleEditOrganizationNewFeatureTypes = (item: OrganizationNewFeatureTypeDto) => {
    const editForm: OrganizationNewFeatureTypeEditFormData = {
      id: item.id,
      name: item.name,
      description: item.description,
    };
    panel({
      title: 'Edit Organization New Feature Type',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <OrganizationNewFeatureTypesForm
            form={editForm}
            onSubmit={async (formData) => {
              await upsertOrganizationNewFeatureTypes(formData);
              onSubmit();
            }}
          />
        );
      },
    });
  };

  const handleDeleteOrganizationNewFeatureTypes = async (item: OrganizationNewFeatureTypeDto) => {
    setLoading(true);
    try {
      const handleDelete = deleteOrganizationNewFeatureTypes(item.id).unwrap();
      await handleDelete;
    } catch (err) {
      toast({
        title: 'Organization New Feature Type delete error',
        description:
          "We've run into a problem deleting the selected Organization New Feature Type, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    toast({
      title: 'Selected Organization New Feature Type deleted',
      description: "We've deleted the Organization New Feature Type you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchOrganizationNewFeatureTypes();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: OrganizationNewFeatureTypeDto) => {
    panel({
      title: 'Delete Organization New Feature Type',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the selected Organization New Feature Type?"
            onConfirm={async () => {
              onSubmit();
              await handleDeleteOrganizationNewFeatureTypes(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: OrganizationNewFeatureTypeDto) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => handleEditOrganizationNewFeatureTypes(item)}
          >
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDeleteConfirm(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const OrganizationNewFeatureTypesColumns: ColumnDefinitionType<OrganizationNewFeatureTypeDto>[] =
    [
      {
        type: ColumnSpecialTypes.date,
        key: 'dateCreated',
        header: 'Date Added',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        header: 'Name',
        key: 'name',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        header: 'Description',
        key: 'description',
        headerSortable: true,
        columnSearchable: true,
      },
      {
        key: 'custom',
        header: 'Actions',
        headerSortable: false,
        render: renderDefaultTableActions,
      },
    ];

  return (
    <BaseLayout>
      <Flex flex={1} direction={'column'}>
        <Flex m={4} justifyContent={'space-between'} align={'center'} minH={'65px'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={AppColors.primary}>
            {title}
          </Text>
          <AppButton
            bg={'white'}
            size={'sm'}
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            onClick={handleCreateOrganizationNewFeatureTypes}
          >
            Add Organization New Feature Type
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          overflow={'hidden'}
          padding={'4'}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={OrganizationNewFeatureTypes || []}
            noDataMessage="No Organization New Feature Type's yet, start by adding some!"
            columns={OrganizationNewFeatureTypesColumns}
          />
          <AppPaginator pagination={pagination} onPageChange={handleOnPageChange}></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default OrganizationNewFeatureTypes;
