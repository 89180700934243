import { useState } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  useFetchAllUsersQuery,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserUpdateMutation,
} from '../../../store/api/userApi';
import {
  AppButton,
  AppColors,
  AppConfirm,
  AppText,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  User,
  UserCreateForm,
  UserEditForm,
  useDataTable,
  useLoading,
  usePanel,
} from '@backlinkit/shared';
import { AppDataTable, AppPaginator, PageChangeEvent } from '@backlinkit/shared';
import RoleCell from './cell/organization-role';
import UsersForm from '../../forms/user-create-form';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import EditUserForm from '../../forms/edit-user-form';

const UsersTable: React.FC = () => {
  const panel = usePanel();
  const toast = useToast();
  const { setLoading } = useLoading();
  const { handleOnPageChange, pagination } = useDataTable<User>({});

  const { data: Users, refetch: refetchUsers } = useFetchAllUsersQuery('', {
    refetchOnMountOrArgChange: true,
  });
  const [saveUserTrigger] = useUserCreateMutation();
  const [editUserTrigger] = useUserUpdateMutation();
  const [deleteUserTrigger] = useUserDeleteMutation();

  const upsertUser = async (formData: any) => {
    setLoading(true);
    let isEdit = formData.id ? true : false;
    try {
      if (!isEdit) {
        const createForm = formData as UserCreateForm;
        await saveUserTrigger({
          email: createForm.email,
          password: createForm.password,
          organizationId: createForm.organizationId,
          organizationRoleId: createForm.organizationRoleId,
        }).unwrap();
      } else {
        const editFormData = formData as UserEditForm;
        await editUserTrigger({
          id: editFormData.id ?? '',
          email: editFormData.email,
          organizationId: editFormData.organizationId,
          organizationRoleId: editFormData.organizationRoleId,
        }).unwrap();
      }
      toast({
        title: `User ${isEdit ? 'updated' : 'created'}`,
        description: `We've ${
          isEdit ? 'updated' : 'created'
        } your user, come back soon to check the status`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchUsers();
    } catch (e) {
      toast({
        title: `User ${isEdit ? 'updating' : 'creating'} error = ${e}`,
        description: `We've run into a problem ${
          isEdit ? 'updating' : 'creating'
        } your user. Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const handleAdd = () => {
    return panel({
      title: 'Add User',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <UsersForm
            onSubmit={async (formData: UserCreateForm) => {
              onSubmit();
              console.log('form', formData);
              await upsertUser(formData);
            }}
          />
        );
      },
    });
  };

  const handleEditUser = (user: User) => {
    const userForm: UserEditForm = {
      id: user.id,
      email: user.email,
      organizationId: user.organizationId,
      organizationRoleId: user.organizationRoleId,
    };
    panel({
      title: 'Edit User',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <EditUserForm
            form={userForm}
            onSubmit={async (formData) => {
              onSubmit();
              upsertUser(formData);
            }}
          />
        );
      },
    });
  };

  const handleUserDelete = async (user: User) => {
    setLoading(true);
    try {
      const deletePromises = deleteUserTrigger(user.id).unwrap();
      await deletePromises;
    } catch (e) {
      toast({
        title: 'User delete error',
        status: 'error',
        description:
          "We've run into an issue deleting the user you selected. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'User deleted',
      status: 'success',
      description: "We've deleted the user you selected",
      duration: 9000,
      isClosable: true,
    });
    refetchUsers();
    setLoading(false);
  };

  const handleDeleteConfirm = (item: User) => {
    panel({
      title: 'Delete user',
      size: 'lg',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message="Are you sure you want to delete the user you selected?"
            onConfirm={async () => {
              onSubmit();
              await handleUserDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: User) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEditUser(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirm(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const userColumns: ColumnDefinitionType<User>[] = [
    {
      key: 'custom',
      header: 'Organization',
      headerSortable: false,
      columnSearchable: true,
      render: (item) => {
        return <AppText>{item.organization?.name}</AppText>;
      },
    },
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'email',
      header: 'e-mail',
      headerSortable: true,
      columnSearchable: true,
    },
    {
      key: 'organizationRoleId',
      header: 'Organization Role',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => {
        return <RoleCell item={item} />;
      },
    },
    {
      key: 'custom',
      header: 'Actions',
      headerSortable: false,
      columnSearchable: false,
      render: renderDefaultTableActions,
    },
  ];

  const [tableColumns, setTableColumns] = useState<ColumnDefinitionType<User>[]>(userColumns);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <>
      <Flex justifyContent={'space-between'} align={'center'} m={4} minH={'65px'}>
        <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
          {'Users'}
        </Text>
        <AppButton size={'sm'} borderRadius={'full'} onClick={handleAdd}>
          Add User
        </AppButton>
      </Flex>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        justify={'space-evenly'}
        rounded={'2xl'}
        overflow={'hidden'}
        padding={4}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={Users || []}
          noDataMessage={'No users added, start by adding some!'}
          columns={tableColumns}
          selectableRows={false}
        ></AppDataTable>
        <AppPaginator
          pagination={pagination}
          onPageChange={(event: PageChangeEvent) => handleOnPageChange(event)}
        ></AppPaginator>
      </Flex>
    </>
  );
};

export default UsersTable;
