import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  OrganizationNewFeatureTypeDto,
  OrganizationNewFeatureTypeFormData,
  OrganizationNewFeatureTypeEditFormData,
} from '@backlinkit/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationNewFeatureTypeApi = createApi({
  reducerPath: 'organizationNewFeatureTypeApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationNewFeatureTypes: build.query<OrganizationNewFeatureTypeDto[], any>({
      query: () => ({
        url: '/organizationNewFeatureType',
        method: 'GET',
      }),
    }),
    fetchOrganizationNewFeatureTypeById: build.query<OrganizationNewFeatureTypeDto, string>({
      query: (id) => ({
        url: `/organizationNewFeatureType/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationNewFeatureType: build.mutation<
      OrganizationNewFeatureTypeDto,
      OrganizationNewFeatureTypeFormData
    >({
      query: (body) => ({
        url: `/organizationNewFeatureType/create`,
        method: 'POST',
        body: body,
      }),
    }),
    updateOrganizationNewFeatureType: build.mutation<
      OrganizationNewFeatureTypeDto,
      OrganizationNewFeatureTypeEditFormData
    >({
      query: (body) => ({
        url: `/organizationNewFeatureType/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
    deleteOrganizationNewFeatureType: build.mutation<OrganizationNewFeatureTypeDto, string>({
      query: (id) => ({
        url: `/organizationNewFeatureType/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationNewFeatureTypeMutation,
  useDeleteOrganizationNewFeatureTypeMutation,
  useFetchAllOrganizationNewFeatureTypesQuery,
  useFetchOrganizationNewFeatureTypeByIdQuery,
  useUpdateOrganizationNewFeatureTypeMutation,
} = organizationNewFeatureTypeApi;
